/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import FlowFinish from '../komponents/Flow/FlowFinish';

const GraciasCompra: FunctionComponent = () => (
  <>
    <Header />

    <FlowFinish />

    <Footer />
  </>
);

export default GraciasCompra;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Gracias por tu compra - PuntoTerminal" />
    <title>Gracias por tu compra - PuntoTerminal.mx</title>
  </>
);
